<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджет</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        @keyPress="keyPress"
                    />
                    <b-button variant="primary" @click="addItem" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> Добавить</b-button>
                    <b-button variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute">Сохранить</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <!-- <td class="td-numbering"></td> -->
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering table-danger">7</td>
                    <td class="td-numbering table-primary">8</td>
                    <td class="td-numbering table-info">9</td>
                    <td class="td-numbering table-warning">10</td>
                    <td class="td-numbering table-primary">11</td>
                    <td class="td-numbering table-success">12</td>
                    <td class="td-numbering table-danger">13</td>
                    <td class="td-numbering table-info">14</td>
                    <td class="td-numbering table-primary">15</td>
                    <td class="td-numbering table-warning">16</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(select)>
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i
                            title="Удалить выбранные записи"
                            class="icon icon-close table-all-remove"
                            @click="deleteItemWithAttachedFiles(`Удалить ${selectAll ? 'все' : 'выбранные'} записи?`)"/>
                    </div>
                </template>

                <template #cell(select)="data">
                    <b-form-checkbox
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <!-- <template #cell(action)="data">
                    <b-button @click="data.item.dict = !data.item.dict">
                        <i class="icon icon-document-pencil" v-if="data.item.dict"></i>
                        <i class="icon icon-full-size" v-if="!data.item.dict"></i>
                    </b-button>
                </template> -->
                <template #cell(model)="data">
                    <template v-if="variantAttribute">
                        <multiselect v-if="data.item.dict"
                                     style="width: 300px"
                                     v-model="data.item.modelName"
                                     track-by="text"
                                     label="text"
                                     placeholder="Выбрать марку автомобиля"
                                     :options="dict"
                                     :searchable="true"
                                     :allow-empty="false"
                                     :show-labels="false"
                                     @input="(dictItem) => changeModel(data.item, dictItem.value)"
                        >
                        </multiselect>
                        <!--                    <b-form-select v-if="data.item.dict"-->
                        <!--                                   size="sm"-->
                        <!--                                   class="w-200px"-->
                        <!--                                   v-model="data.item.code"-->
                        <!--                                   :options="dict"-->
                        <!--                                   @change="changeModel(data.item, data.item.code)"-->
                        <!--                    >-->
                        <!--                    </b-form-select>-->
                        <b-form-input v-if="!data.item.dict"
                                      :value="data.item.model"
                                      @change="v => data.item.model = v"
                                      @keyup.enter.exact="keyup13">
                        </b-form-input>
                    </template>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(winter)="data">
                    <b-form-select v-if="variantAttribute"
                                   size="sm"
                                   style="width: 100px"
                                   v-model="data.item.winter"
                                   :options="win_sum"
                    >
                    </b-form-select>
                    <div v-else>{{ win_sum.find(el => el.value === data.value).text }}</div>
                </template>
                <template #cell(months)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.months"
                                  @change="v => data.item.months = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixedVldtn(data.item, 'months', data.item.months, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(kind_fuel)="data">
                    <b-form-select v-if="variantAttribute"
                                   size="sm"
                                   style="width: 100px"
                                   v-model="data.item.kind_fuel"
                                   :options="dictGsm"
                    >
                    </b-form-select>
                    <div v-else>{{ dictGsm.find(el => el.value === data.value).text }}</div>
                </template>
                <template #cell(number_cars)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.number_cars"
                                  @change="v => data.item.number_cars = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixedVldtn(data.item, 'number_cars', data.item.number_cars, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(engine)="data">
                    <template v-if="variantAttribute">
                        <template v-if="data.item.dict">
                            <template v-if="(data.item.model !== null) && (data.item.model.engine == null)">
                                <b-form-input class="text-right"
                                              :value="data.item.engine"
                                              @change="v => data.item.engine = v"
                                              @keyup.enter.exact="keyup13"
                                              @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                              @blur="inputFixedVldtn(data.item, 'engine', data.item.engine, 2)">
                                </b-form-input>
                            </template>
                            <template v-else>
                                <div class="text-right">{{ $n(data.value) }}</div>
                            </template>
                        </template>
                        <b-form-input v-if="!data.item.dict"
                                      class="text-right"
                                      :value="data.item.engine"
                                      @change="v => data.item.engine = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                      @blur="inputFixedVldtn(data.item, 'engine', data.item.engine, 2)">
                        </b-form-input>
                    </template>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(base_rate)="data">
                    <!--                            <div v-if="data.item.dict" class="text-right">{{ $n(data.value) }}</div>-->
                    <b-form-input v-if="variantAttribute"
                        class="text-right"
                        :value="data.item.base_rate"
                        @change="v => data.item.base_rate = v"
                        @keyup.enter.exact="keyup13"
                        @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                        @blur="inputFixedVldtn(data.item, 'base_rate', data.item.base_rate, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(limit_mile)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.limit_mile"
                                  @change="v => data.item.limit_mile = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixedVldtn(data.item, 'limit_mile', data.item.limit_mile, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(cost_fuel)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.cost_fuel"
                                  @change="v => data.item.cost_fuel = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'cost_fuel', data.item.cost_fuel, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(cost_coeff)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.cost_coeff"
                                  @change="v => data.item.cost_coeff = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\-?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'cost_coeff', data.item.cost_coeff, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell()="data">
                    <div class="text-right">{{ isNaN(data.value) ? 0 : $n(data.value) }}</div>
                </template>
                <template #cell(note)="data">
                    <div :class="{ 'error': ((data.item.note && data.item.note.length > 100)
                    || (data.item.cost_coeff !== 0 && data.item.note.trim().length === 0)) }">
                        <b-form-input
                            class="text-right"
                            style="min-width: 90%"
                            :value="data.item.note"
                            @change="v => data.item.note = v"
                            @keyup.enter.exact="keyup13">
                        </b-form-input>
                        <template v-if="(data.item.note && data.item.note.length > 100)">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                <p>Ограничение 100 символов</p>
                            </div>
                        </template>
                        <template v-else-if="(data.item.cost_coeff !== 0 && data.item.note.trim().length === 0)">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                <p>Обязательное поля для заполнения</p>
                            </div>
                        </template>
                    </div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="openModalRowFilesByRowId(data.item.id, false)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <b-dropdown v-if="variantAttribute" id="dropdown-dropleft" dropleft class="more">
                        <template v-slot:button-content>
                            <i class="icon icon-more"></i>
                        </template>
                        <template>
                            <b-dropdown-item @click="openModalRowFilesByRowId(data.item.id, true)">
                                Добавить файл(ы)
                            </b-dropdown-item>
                            <b-dropdown-item v-if="variantAttribute" @click="deleteItemWithAttachedFiles('Удалить запись?', data.item, data.index)">
                                Удалить
                            </b-dropdown-item>
                        </template>
                    </b-dropdown>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="12">ИТОГО</td>
                    <td class="text-right">{{ isNaN(cost_allcar) ? 0 : $n(cost_allcar) }}</td>
                    <td class="text-right">{{ isNaN(cost_coeff) ? 0 : $n(cost_coeff) }}</td>
                    <td class="text-right">{{ isNaN(total) ? 0 : $n(total) }}</td>
                    <td colspan="3"></td>
                </template>
            </b-table>
        </div>
        <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
            <span @click="addItem"><i class="icon icon-plus-circle"></i> Добавить</span>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      ref="fileUpdown"
        ></files-updown>
        <modal-attach ref="modalAttach"
                      :row-files-input="row_files"
                      :header="header"
                      :is-add="isAdd"
                      :variant-attribute="variantAttribute"
                      @toggleIsAdd="toggleIsAdd($event)"
                      @fileUpload="fileUpload"/>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import { Ax } from '@/utils';
// import BudgetAttachFile from "./components/budget-attach-file";
import Multiselect from 'vue-multiselect';
import ModalAttach from '@/modules/budget-request/components/modal-attach.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";

export default {
    name: 'Form01_144',
    components: { BudgetHeader, FilesUpdown, BudgetFormsList, FormsDownloadReprt, 'multiselect': Multiselect, BreadcrumbsFilter, ModalAttach, CBudgetFormsCopyData },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '01-144',
                name_kk: 'Мемлекеттiк органның жанар-жағармай материалдарына арналған шығыстарды есептеу',
                name_ru: 'Расчет расходов государственных органов на горюче-смазочные материалы'
            },
            tableFields: [
                {
                    key: 'select',
                    label: ' '
                },
                // {
                //     key: 'action',
                //     label: ' '
                // },
                {
                    key: 'model',
                    label: 'Марка автомобиля'
                },
                {
                    key: 'winter',
                    label: 'Зима/Лето'
                },
                {
                    key: 'kind_fuel',
                    label: 'Вид ГСМ'
                },
                {
                    key: 'months',
                    label: 'Количество месяцев'
                },
                {
                    key: 'number_cars',
                    label: 'Количество служебного автотранспорта'
                },
                {
                    key: 'engine',
                    label: 'Объем двигателя, в кубических сантиметрах (далее - куб.см)'
                },
                {
                    key: 'base_rate',
                    label: 'Базовая норма л/100 км'
                },
                {
                    key: 'limit_mile',
                    label: 'Лимит пробега в месяц'
                },
                {
                    key: 'cost_rate',
                    label: 'Норма расходов ГСМ на лимит пробега в месяц ((гр.8/100) x гр.7)'
                },
                {
                    key: 'cost_fuel',
                    label: 'Стоимость ГСМ на 1 литр '
                },
                {
                    key: 'cost_onecar',
                    label: 'Сумма расходов ГСМ на одну машину в месяц в (тыс.тенге) (гр.9 х гр.10)'
                },
                {
                    key: 'cost_allcar',
                    label: 'Сумма расходов ГСМ на все машины в год в (тыс.тенге) (гр.11 х гр.5 x гр.4)'
                },
                {
                    key: 'cost_coeff',
                    label: 'Сумма расходов на поправочные значения (+/- тыс.тг)'
                },
                {
                    key: 'cost_total',
                    label: 'Всего расходов (гр.9 + гр.10), тыс.тенге'
                },
                {
                    key: 'note',
                    label: 'Примечания'
                },
                {
                    key: 'files',
                    label: 'Файлы'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            dict: [],
            dictClimatConds: [],
            win_sum: [
                {
                    value: 0,
                    text: 'Лето'
                },
                {
                    value: 1,
                    text: 'Зима'
                },
                {
                    value: 3,
                    text: 'Общее'
                }
            ],
            files: null,
            load: false,
            openDisabled: false,
            dictGsm: [],
            isLoad: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
        };
    },
    async mounted() {
        await this.loadClimats();
        await this.loadGsm();
        await this.loadDict();
    },
    methods: {
        openModalRowFilesByRowId(rowId, isAdd) {
            this.row_files = this.budgetForm.find(b => b.id === rowId)['row_files'];
            this.$refs.modalAttach.openModalRowAddFilesByRowId(rowId);
            this.rowId = rowId;
            this.isAdd = isAdd;
        },
        toggleIsAdd(return_object) {
            const curBudgetForm = this.budgetForm.find(b => b.id === this.rowId)
            curBudgetForm['row_files'] = return_object['row_files']
            curBudgetForm['files'] = return_object['num_attach_files']
            this.isAdd = return_object['isAdd'];
        },
        fileUpload(rowId) {
            this.$refs.fileUpdown.openModalFileUpload();
            this.$refs.fileUpdown.setRowId(rowId);
        },
        getNewFiles(files) {
            this.budgetForm.forEach(item => {
                let num_attach_files = 0;
                item['row_files'] = [...item['row_files'], ...files];
                item['row_files'].forEach((file) => {
                    if (file.row_id === item['id']) {
                        num_attach_files += 1;
                    }
                });
                item['files'] = num_attach_files;
            });
            this.$refs.modalAttach.addNewFiles(files);
        },
        delFile(fileId) {
            this.budgetForm.forEach(item => {
                item['row_files'].forEach((file, index) => {
                    if (file.file_id === fileId) {
                        item['row_files'].splice(index, 1);
                    }
                });
                item.files = item['row_files'].filter(i => i.row_id !== null).length;
            });
            this.$refs.modalAttach.delFile(fileId);
        },
        async addItem() {
            const item = {
                id: this.newRowStartId,
                dict: true,
                code: null,
                model: null,
                engine: 0,
                base_rate: 0,
                winter: 0,
                months: 0,
                kind_fuel: '1001',
                number_cars: 0,
                cost_fuel: 0,
                cost_coeff: 0,
                modelName: null,
                itemToDelete: false,
                note: ''
            };
            this.newRowStartId--;

            this.$set(item, 'files', 0);

            try {
                this.load = true;
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/` + JSON.stringify(this.header));
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса itemUpdate', error.toString());
            }
            this.load = false;

            Object.defineProperty(item, 'cost_rate', {
                get: function () {
                    const costRate = item.limit_mile / 100 * item.base_rate;
                    return parseFloat((Math.round(costRate * 100) / 100).toFixed(2));
                }
            });
            Object.defineProperty(item, 'cost_onecar', {
                get: function () {
                    const costOnecar = item.cost_rate * item.cost_fuel / 1000;
                    return parseFloat((Math.round(costOnecar * 100) / 100).toFixed(2));
                }
            });
            Object.defineProperty(item, 'cost_allcar', {
                get: function () {
                    const costAllcar = item.cost_onecar * item.number_cars * item.months;
                    return parseFloat((Math.round(costAllcar * 100) / 100).toFixed(2));
                }
            });
            Object.defineProperty(item, 'cost_total', {
                get: function () {
                    const total = parseFloat(item.cost_allcar) + parseFloat(item.cost_coeff);
                    return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                }
            });
            this.budgetForm.push(item);
        },

        changeModel(item, model) {
            this.$set(item, 'code', model);
            this.$set(item, 'model', model.model);
            this.$set(item, 'engine', (model.engine === null ? null : model.engine / 1000));
        },

        calcTotal(ids) {
            this.$set(this.header, 'value', this.calcTotalForDelete(this.budgetForm, 'cost_total', ids));
        },

        downloadRep() {
            Ax(
                {
                    url: '/api-py/budg_' + this.form.code.replace('-', '_') + '/' + JSON.stringify(this.header),
                    method: 'POST',
                    data: this.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Форма 01-144.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                }
            );
        },

        getFiles(data) {
            this.files = data;
        },

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas() {
            this.load = true;
            await this.budgetForm.splice(0);
            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                values = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDatas()', error.toString());
            }

            await values.forEach(val => {
                const item = {
                    id: val.id,
                    dict: false,
                    code: null,
                    model: val.model,
                    engine: val.engine,
                    base_rate: val.base_rate,
                    winter: val.winter,
                    months: val.months,
                    kind_fuel: val.kind_fuel,
                    number_cars: val.number_cars,
                    limit_mile: val.limit_mile,
                    cost_fuel: val.cost_fuel,
                    files: val.files,
                    row_files: val.row_files,
                    itemToDelete: false,
                    cost_coeff: val.cost_coeff
                };

                val.note ? this.$set(item, 'note', val.note)
                    : this.$set(item, 'note', '');

                // that.changeModel(item, item.model);
                // this.changeClimat(item, item.winter);

                Object.defineProperty(item, 'cost_rate', {
                    get: function () {
                        const costRate = item.limit_mile / 100 * item.base_rate;
                        return parseFloat((Math.round(costRate * 100) / 100).toFixed(2));
                    }
                });
                Object.defineProperty(item, 'cost_onecar', {
                    get: function () {
                        const costOnecar = item.cost_rate * item.cost_fuel / 1000;
                        return parseFloat((Math.round(costOnecar * 100) / 100).toFixed(2));
                    }
                });
                Object.defineProperty(item, 'cost_allcar', {
                    get: function () {
                        const costAllcar = item.cost_onecar * item.number_cars * item.months;
                        return parseFloat((Math.round(costAllcar * 100) / 100).toFixed(2));
                    }
                });
                Object.defineProperty(item, 'cost_total', {
                    get: function () {
                        const total = parseFloat(item.cost_allcar) + parseFloat(item.cost_coeff);
                        return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                    }
                });

                this.budgetForm.push(item);
            });
            this.load = false;
        },

        async loadClimats() {
            try {
                const response = await fetch('/api-py/dictionary/climat_conds/');
                this.dictClimatConds = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadClimats()', error.toString());
            }
        },

        async loadGsm() {
            try {
                this.dictGsm = [];
                const response = await fetch('/api-py/dictionary/dict_gsm/');
                const gsmList = await response.json();
                gsmList.forEach(gsm => {
                    this.dictGsm.push({
                        value: gsm.code,
                        text: gsm.name_ru
                    });
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadGsm()', error.toString());
            }
        },

        async loadDict() {
            try {
                this.dict = [];
                const response = await fetch('/api-py/dictionary/transport_models/');
                const items = await response.json();

                for (const row of items) {
                    const el = {};
                    this.$set(el, 'value', row);
                    this.$set(el, 'text', row.model + ' / ' + (row.engine / 1000).toFixed(1).toString() + ' / ' + row.gearbox + ' / ' + row.base_rate);
                    this.dict.push(el);
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadTModels()', error.toString());
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        prepareForSave() {
            const values = [];
            let error = false;
            const template = (row) => {
                const item = Object.assign({}, this.header);
                this.$set(item, 'id', row.id);
                this.$set(item, 'model', row.model);
                this.$set(item, 'engine', parseFloat(row.engine));
                this.$set(item, 'base_rate', parseFloat(row.base_rate));
                this.$set(item, 'winter', row.winter);
                this.$set(item, 'number_cars', parseInt(row.number_cars));
                this.$set(item, 'months', parseInt(row.months));
                this.$set(item, 'kind_fuel', row.kind_fuel);
                this.$set(item, 'limit_mile', parseInt(row.limit_mile));
                this.$set(item, 'cost_fuel', parseFloat(row.cost_fuel));
                this.$set(item, 'cost_coeff', parseFloat(row.cost_coeff));
                this.$set(item, 'note', row.note);
                this.$set(item, 'row_files', row.row_files);
                this.$set(item, 'cost_total', row.cost_total);

                values.push(item);
            }
            for (const row of this.budgetForm) {
                if (row.cost_coeff === 0 && (row.note === null || row.note.length < 101)) {
                    template(row);
                } else if (row.cost_coeff !== 0 && row.note.trim().length !== 0 && row.note.length < 101) {
                    template(row);
                } else {
                    error = true;
                }
            }
            if (error) {
                this.onFieldsValidationFailed();
                return;
            }
            if (values.length > 0 && this.variantAttribute) {
                this.save(values, error);
            } else {
                this.makeToast('warning', 'Сообщение', 'Данные не сохранены');
            }
        },

        async save(values, error) {
            this.isLoad = true;
            const totalToSave = this.totalCalculation(values, 'cost_total')
            try {
                this.$set(this.header, 'value', totalToSave);
                const response = await fetch('/api-py/save-budget-request-form01-144/' +
                    JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    // await this.saveTotal();
                    if (this.files.length === 0) {
                        this.makeToast('danger', 'Предупреждение', 'Внимание! Отсутствуют необходимые документы. Пожалуйста, прикрепите недостающие файлы.');
                    }
                    this.makeToast('success', 'Сообщение', 'Данные сохранены');
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw 'Ошибка сохранения данных. Возможно не все обязательные поля заполнены';
                }
            } catch (e) {
                this.makeToast('danger', 'Предупреждение', e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        async saveTotal() {
            this.$set(this.header, 'value', this.total);
            try {
                const response = await fetch('/api-py/save-budget-request-total/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.header)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    // this.makeToast('success', 'Сообщение', 'Данные сохранены');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка сохранения итога');
            }
        } // сохранение итога
    },
    computed: {
        cost_allcar() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.cost_allcar);
            }
            return sum;
        },
        cost_coeff() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.cost_coeff);
            }
            return sum;
        },
        total() {
            return this.totalCalculation(this.budgetForm, 'cost_total')
        },
        regCoeff() {
            const reg = this.padLeadingZeros(this.header.id_region.toString(), 6).substring(0, 2);

            for (const item of this.dictClimatConds) {
                if (item.code === reg) {
                    return parseFloat(item.coefficient);
                }
            }
            return 0;
        }
    }
};
</script>
<style scoped>
    .filter-actions-flex {
        display: flex;
    }
</style>